<template>
  <div class="info">
    <div class="jobshwheader"></div>
    <div class="min_body">
      <div class="job_describe">
        <!-- 职位要求及待遇岗位 -->
        <div class="job_describe_top">
          <div class="jjxx">用户投诉信息处理</div>
        </div>
        <div class="sj_job_box mt10"></div>
        <div class="job_describe_cengter">
          <div class="user_undergo_box">
            <div class="user_undergo user_undergo_a">
              地址信息：{{ info.DD_XX }}
            </div>
            <div class="user_undergo user_undergo_a">
              投诉标题：{{ info.Title }}
            </div>
            <div class="user_undergo user_undergo_a">
              投诉内容：{{ info.Count }}
            </div>
            <div class="user_undergo user_undergo_a">
              图片信息：
            </div>
            <img
              :src="info.FileList"
              style="width: 80px;height:80px;display: inline-block;padding: 2px"
              @click="previewBefore"
            />
          </div>
        </div>
        <div v-if="flag === true" style="font-size: 12px">
          <div
            style="font-size: 20px;text-align: center;background-color: rgb(217 226 243)"
          >
            回复记录
          </div>
          <div>
            <ul>
              <li v-for="(item, i) in replyList" :key="i" class="lyk">
                <div>
                  <p>
                    回复人：
                    <span>{{ item.Staff_Name }}</span>
                    <br />
                    回复时间：
                    <span>{{ item.Date_Op }}</span>
                    <br />
                    回复内容：
                    <span>{{ item.HF }}</span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <van-form>
            <van-field
              v-model="hfNr"
              rows="4"
              autosize
              label="回复内容"
              type="textarea"
              placeholder="请填写回复内容"
            />

            <div style="margin: 16px;">
              <van-button round block type="info" @click="Save()">
                回复
              </van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import Base64 from '@/util/Base64'
  import { Dialog, ImagePreview, Toast } from 'vant'
  import { systemUrl } from '@/config'
  import {
    GetShopComplainInfo,
    ShopComlainCellBack,
  } from '@/api/workBench/shopComplain/ShopComplain'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'ShopComplainInfo',
    data() {
      return {
        showStatus: false,
        name: '',
        info: {}, //详情数据
        fileList: '',
        flag: false,
        formData: {
          ID: '',
          Count: '',
        },
        hfNr: '',
        filePath: '',
      }
    },
    created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      this.formData.UserID = userInfo.User_ID
      this.formData.ID = Base64.decode(this.$route.query.ID)
      this.fetchData()
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'shopComplainInfo',
        '城管-商家投诉详情',
        'zzdcg.yy.gov.cn/shopComplainInfo'
      )
    },
    methods: {
      //获取事件审批详情
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await GetShopComplainInfo({
          ID: this.formData.ID,
        })
        let fileList = systemUrl + data.File_Path
        data.FileList = fileList
        this.info = data
        Toast.clear()
      },
      async Save() {
        //网格员回复
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        this.formData.Count = this.hfNr == '' ? '已处理！' : this.hfNr
        const { success, data, msg } = await ShopComlainCellBack(this.formData)
        if (success) {
          Toast.clear()
          Dialog({ message: msg })
          this.onClickRight()
        } else {
          Toast.clear()
          Dialog({ message: msg })
        }
      },
      previewBefore() {
        ImagePreview({
          images: this.fileList,
        })
      },
      getFileList(val) {
        console.log(val)
        this.fileList = val
      },
      //跳转到流程列表
      onClickRight() {
        this.$router.push({
          path: '/shopComplain',
        })
      },
      onConfirmStatus(value) {
        this.value = value
        this.name = value.text
        this.formData.verified = value.value
        this.showStatus = false
      },
    },
  }
</script>

<style scoped>
  .job_describe_cengter {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }
  .job_describe_cengter2 {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }

  .job_describe_cengter_header {
    font-size: 0.426667rem;
    font-weight: 700;
    color: #181818;
  }

  .job_describe_top {
    position: relative;
    width: 100%;
    padding: 0.273333rem 0.373333rem;
    font-size: 0.373333rem;
    background-color: #fff;
    border-bottom: 0.013333rem solid #eee;
  }
  .jjxx {
    position: relative;
    padding-left: 0.473333rem;
    font-size: 0.426667rem;
    font-weight: bold;
  }
  .jjxx::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background: url(../../../../assets/images/workBench/detail.png) no-repeat;
    background-size: auto;
    background-size: 100%;
  }
  .job_describe {
    width: 100%;
    margin-top: -1.333333rem;
    overflow: hidden;
    line-height: 1.8;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
  .min_body {
    padding-right: 0.32rem;
    padding-left: 0.32rem;
  }
  .jobshwheader {
    width: 100%;
    height: 2.026667rem;
    font-size: 0.32rem;
    background-color: #2778f8;
  }
  .user_undergo {
    width: 100%;
    height: 0.8rem;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .user_undergo2 {
    width: 100%;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 2;
    color: #333;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
  .user_undergo_box {
    padding: 0rem 0.4rem 0rem 0rem;
  }
  .user_undergo_a {
    position: relative;
  }
  .user_undergo_a::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background-size: auto;
    background-size: 100%;
  }
  .corporate_information {
    margin-top: 0.266667rem;
    overflow: hidden;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
  .lyk {
    border: 2px #ccc dotted;
    padding: 0 8px 0 8px;
    margin: 10px;
  }
</style>
